.AvatarUploader {
  width: 200px;
  text-align: center;
}

.AvatarUploader button {
  font-family: unset;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  border-radius: 6px;
  color: white;
  background-color: var(--jetstream-blue);
  margin: 4px;
}

.AvatarUploader button:hover {
  background-color: var(--jetstream-blue-highlight);
}

.AvatarUploader--Empty {
  border: 2px dashed #333;
  width: 200px;
  height: 200px;
  text-align: center;
  cursor: pointer;
}

.AvatarUploader--Empty > p {
  padding-top: 50px;
}