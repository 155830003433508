.Login input:focus,
.Login select:focus,
.Login textarea:focus,
.Login button:focus {
    outline: none;
}

.Login {
  position: relative;
}

.Login__Wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  position: absolute;
  top: -25px;
}

.Login__Card {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
}

.Login__Card h1 {
  margin: 0 0 20px 0;
  padding: 4px;
  font-size: 18px;
  font-weight: 400;
}

.Login__Card ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.Login__Card ul li {
  list-style: none;
  margin: 4px 0;
  padding: 4px;
  text-align: left;
  cursor: pointer;
  flex-direction: column;
  flex: 45%;
  max-width: 45%;
  transition: background-color 0.1s ease-out;
}

.Login__Card form input {
  width: 100%;
}

.Login__Card ul li:hover {
  background-color: var(--jetui-color-surface);
}

.Login__Card ul li > img {
  height: 48px;
  width: 48px;
  vertical-align: middle;
}

.Login__Card form span.jetui-input-container {
  display: block;
}

.Login__Card form label.Login__Card__Error {
  color: black;
}

.Login__Logo {
  font-weight: 900; 
  font-size: 30px;
  display: inline-block;
  margin: 40px auto 40px auto;
  position: relative;
}

.Login__Logo img {
  vertical-align: middle;
  height: 80px;
  padding-right: 10px;
}

.Login__Card__ExternalProviders h1 {
  font-size: 14px;
}

.Login__Error {
  margin-bottom: 16px;
  border-radius: 2px;
  background-color: var(--jetui-color-error);
  padding: 8px;
  color: var(--jetui-color-background);
}

.Login__Card button {
  width: 200px;
}

.Login__Card .jetui-input-container {
  text-align: left;
}