.jetui-status-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.jetui-status {
  width: 0%;
  background-color: var(--jetui-color-primary);
  color: #fafafa;
  padding: 0;
  margin: 0 8px;
  border-radius: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  transition: all 0.3s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  opacity: 0.0;
}

.jetui-status-open {
  width: 100%;
  opacity: 1.0;
}

.jetui-status i {
  margin: 6px;
  font-size: 24px;
  color: #fafafa;
}

.jetui-status-info {
  background-color: var(--jetui-color-primary);
  color: #fafafa;
}

.jetui-status-good {
  background-color: var(--jetui-color-good);
  color: #fafafa;
}

.jetui-status-warning {
  background-color: var(--jetui-color-warning);
  color: #fafafa;
}

.jetui-status-error {
  background-color: var(--jetui-color-error);
  color: #fafafa;
}

.jetui-status-neutral {
  background-color: var(--jetui-color-surface);
}