.LogSearch {
  float: right;
  font-family: monospace;
  font-size: 12px;
  margin-top: 4px;
}

.LogSearch > input[type=text] {
  background-color: rgba(255,255,255,0.15);
  border-bottom: none;
  color: #fafafa;
  padding: 4px 28px 6px 4px;
  font-family: unset;
  width: 24px;
  transition: width .2s ease-out;
  border: none;
}

input[type=text].LogSearch__Active {
  width: 120px;
}

.LogSearch__Icon {
  margin-left: -24px;
  margin-right: 8px;
}

.LogSearch__Control {
  cursor: pointer;
}

.LogSearch button {
  border-radius: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}