.Header {
  background-color: var(--jetui-color-surface);
}

.Header__MenuBars {
  margin: 0 8px;
  background-color: unset;
  color: unset;
  padding: 0;
}

.Header__Slider {
  padding: 8px;
}

.Header__Slider__MenuItem {
  display: block;
  font-size: 16px;
  padding: 4px 0;
}