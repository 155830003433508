.jetui-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--jetui-color-primary);
  color: var(--jetui-color-primary-light);
  font-weight: 900;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  user-select: none;
  vertical-align: middle;
}