.JobEntry {
    cursor: pointer;
    padding: 8px;
    border-top: 1px solid var(--jetui-color-surface-light);
}

.JobEntry:last-child {
    border-bottom: 1px solid var(--jetui-color-surface-light);
}

.jetui-theme-light .JobEntry:last-child {
    border-bottom: 1px solid var(--jetui-color-surface);
}

.jetui-theme-light .JobEntry {
    border-top: 1px solid var(--jetui-color-surface);
}

.JobEntry__Name {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    width: 75%;
    padding: 4px 0;
}

.JobEntry:hover {
    background-color: var(--jetui-color-surface-light);
}

.jetui-theme-light .JobEntry:hover {
    background-color: var(--jetui-color-surface);
}

.JobEntry__StatusDot {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-right: 8px;
    background-color: var(--jetui-color-primary-light);
    display: inline-block;
    vertical-align: middle;
}

.JobEntry__StatusDot.JobEntry__StatusDot--success {
    background-color: var(--jetui-color-good);
}

.JobEntry__StatusDot.JobEntry__StatusDot--failure {
    background-color: var(--jetui-color-error);
}

.JobEntry__StatusDot.JobEntry__StatusDot--cancel {
    background-color: #888;
}

.JobEntry__StatusDot--running {
    margin-right: 6px;
}

.JobEntry__Menu {
    float: right;
}