.JobLog {
  background-color: #111;
  color: #ddd;
  padding: 0 10px 10px 10px;
  overflow-y: auto;
  white-space: nowrap;
  flex: 0 1 auto;
  height: 600px;
  font-size: 12px;
  line-height: 1.5em;
}

.JobLog__Header {
  padding: 8px 10px;
  color: #ddd;
  background-color: #111;
  flex: 0 1 auto;
}

.JobLog__Header > h2 {
  display: inline-block;
  max-width: calc(100% - 160px);
  overflow: hidden;
  text-overflow: ellipsis;
}


.JobLog::-webkit-scrollbar {
  width: 18px;
  height: 18px;
  background-color: transparent;
}

.JobLog::-webkit-scrollbar-thumb
{
  border: 4px solid rgba(0,0,0,0);
  background-clip: padding-box;
  background-color: rgba(255,255,255,0.10);
}

.JobLog::-webkit-scrollbar-corner
{
  width: 0px;
  height: 0px;
  background-color: transparent;
}

.JobLog p {
  margin: 0;
  font-family: monospace;
}

.JobLog mark {
  background-color: var(--jetui-color-primary-dark);
  color: unset;
}

mark#currentMatch {
  background-color: #817c36;
}

.JobLog span {
  color: #666;
  margin-right: 14px;
  display: inline-block;
  user-select: none;
  font-family: monospace;
}

.JobLog.JobLog--disabled {
  background-color: #0a0a0a;
  text-align: center;
  font-family: unset;
  padding-top: 40px;
}