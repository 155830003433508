.Components__Dialog__Error {
  color: #f3f3f3;
  background-color: #111;
  padding: 12px;
}

.Components__Dialog__Error > pre {
  width: 100vw;
  white-space: pre-line;
  overflow-y: auto;
}

.Components__Dialog__Error::-webkit-scrollbar {
  width: 18px;
  height: 18px;
  background-color: transparent;
}

.Components__Dialog__Error::-webkit-scrollbar-thumb
{
  border: 4px solid rgba(0,0,0,0);
  background-clip: padding-box;
  background-color: rgba(255,255,255,0.10);
}

.Components__Dialog__Error::-webkit-scrollbar-corner
{
  width: 0px;
  height: 0px;
  background-color: transparent;
}