.jetui-card {
  border-radius: 2px;
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,0.192), 0 0.3px 0.9px 0 rgba(0,0,0,0.168);
}

.jetui-theme-dark .jetui-card {
  background-color: var(--jetui-color-card);
  box-shadow: 0 1.6px 3.6px 0 rgba(1,1,1,0.492), 0 0.3px 0.9px 0 rgba(1,1,1,0.468);
}

.jetui-theme-dark .jetui-card .jetui-card {
  background-color: var(--jetui-color-surface);
}

.jetui-theme-dark .jetui-card .jetui-card .jetui-card {
  background-color: var(--jetui-color-surface-light);
}

.jetui-theme-light .jetui-card .jetui-card {
  background-color: var(--jetui-color-surface-light);
}

.jetui-theme-dark .jetui-card .jetui-card .jetui-card {
  background-color: var(--jetui-color-surface);
}

.jetui-card-dense {
  padding: 4px;
}

.jetui-card-normal {
  padding: 8px;
}

.jetui-card-large {
  padding: 16px;
}