.FlowTable__Histogram {
  height: 2em;
}

.FlowTable__CardHistogram {
  height: 80px;
  text-align: right;
}

.FlowTable__CardHistogram svg{
  width: 100%;
}

.FlowTable__Histogram svg {
  height: 100%;
}

.FlowTable rect,
.FlowTable__Card rect {
  fill: var(--jetui-color-primary);
}

.FlowTable rect:hover,
.FlowTable__Card rect:hover {
  fill: var(--jetui-color-primary-light);
}

div.FlowTable__Card.jetui-card {
  margin-bottom: 16px;
  background-color: var(--jetui-color-surface);
}

div.FlowTable__Card.jetui-card:last-child {
  margin-bottom: 0;
}

.FlowTable__Loading {
  text-align: center;
}