.FlowGraph--pulse {
  fill: orange;
  stroke: orange;
  fill-opacity: 0;
  animation-duration: 2s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  from {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  to {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
}