.Flow-Nav ul {
    margin: 0;
    padding: 0;
  }
  
  .Flow-Nav ul li {
    list-style: none;
    display: inline;
  }

  .ToolButton i {
    vertical-align: middle;
    margin-right: 4px;
    color: var(--jetui-color-primary);
  }
  
  .Flow-Nav .ToolButton {
    padding: 4px;
    margin-bottom: 8px;
    margin-right: 16px;
  }
  
  .Flow-Nav .ToolButton:hover {
    background-color: var(--jetui-color-surface-light);
  }