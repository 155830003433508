.jetui-textbox-container > textarea {
  background-color: unset;
  border-radius: 2px;
  border: 2px solid var(--jetui-color-surface);
  resize: none;
  width: 100%;
  outline: none;
  font-family: unset;
  font-size: unset;
  font-weight: unset;
  transition: border .2s ease-out;
  color: unset;
  padding: 8px;
  box-sizing: border-box;
}

.jetui-theme-dark .jetui-textbox-container > textarea {
  border: 2px solid var(--jetui-color-surface-light);
}

.jetui-textbox-container > textarea:focus {
  border: 2px solid var(--jetui-color-primary);
}

.jetui-textbox-container {
  position: relative;

}

.jetui-textbox-container > label {
  position: absolute;
  top: -16px;
  left: 0px;
  font-size: 12px;
  transition: all .2s ease-out;
  color: #888;
}

.jetui-textbox-container > textarea:placeholder-shown ~ label {
  top: 8px;
  left: 8px;
  font-size: unset;
}

.jetui-textbox-container > textarea:focus ~ label {
  position: absolute;
  top: -16px;
  left: 0px;
  font-size: 12px;
  transition: all .2s ease-out;
}

.jetui-textbox-container > i {
  position: absolute;
  top: 8px;
  right: 8px;
  color: var(--jetui-color-error);
}