.jetui-slider {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--jetui-color-background);
  box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
  width: 200px;
  z-index: 999999;
}

.jetui-slider-open {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.jetui-slider-closed {
  -webkit-animation: slide-out-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-out-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
}
