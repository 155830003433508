.jetui-table-container {
  position: relative;
}

.jetui-table-loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0,0,0,0.10);
  display: flex;
  align-items: center;
  justify-content: center;
}

.jetui-theme-dark .jetui-table-loading-overlay {
  background-color: rgba(1,1,1,0.25);
}

.jetui-table {
  margin: 18px 0;
  padding: 0;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.jetui-table-spinner-column {
  text-align: center;
}

.jetui-table > tbody > tr > td.jetui-table-norecords {
  text-align: center;
  padding: 12px;
}

.jetui-table > thead > tr {
  border-bottom: 1px solid var(--jetui-color-surface);
}

.jetui-theme-dark .jetui-table > thead > tr {
  border-bottom: 1px solid var(--jetui-color-text-darkened);
}

.jetui-table > thead > tr > th {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding: 0 6px;
}

.jetui-table > tbody > tr > td {
  padding: 6px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jetui-theme-dark .jetui-table > tbody > tr:nth-child(odd) {
  background-color: var(--jetui-color-surface);
}

.jetui-table > tbody > tr:nth-child(odd) {
  background-color: var(--jetui-color-surface-light);
}

.jetui-table-paginator {
  text-align: right;
}

.jetui-table-paginator button {
  background-color: transparent;
  padding: 4px;
  color: var(--jetui-color-primary);
}

.jetui-table-paginator button:focus {
  outline: none;
}

.jetui-table-filter {
  margin: 0;
  height: 30px;
}

.jetui-table-selectfilter {
  display: inline-block;
  width: 100%;
}

.jetui-table-selectfilter select {
  min-width: unset;
  width: 100%;
}

.jetui-table-search {
  margin: 0;
  width: 100%;
  height: 30px;
  position: relative;
}

.jetui-table-search .jetui-input-container {
  width: 250px;
  display: inline-block;
  position: absolute;
  right: 0;
  text-align: right;
}

.jetui-table-search input {
  width: 150px;
  display: inline-block;
  transition: width .2s ease-in-out;
}

.jetui-table-search input:focus {
  width: 250px;
  display: inline-block;
}

.jetui-table-search i {
  position: absolute;
  right: 0;
}

.jetui-table-addaction-panel {
  overflow: hidden;
  text-overflow: clip;
  transition: height .2s ease-in-out;
}

.jetui-table-addaction {
  border-radius: 2px;
  box-shadow: 0 0px 0px 0 rgba(0,0,0,0.0), 0 0px 0px 0 rgba(0,0,0,0.0);
  transition: box-shadow .2s ease-in-out;
}

.jetui-table-addaction-open {
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,0.192), 0 0.3px 0.9px 0 rgba(0,0,0,0.168);
}

.jetui-theme-dark .jetui-table-addaction-open {
  box-shadow: 0 1.6px 3.6px 0 rgba(1,1,1,0.492), 0 0.3px 0.9px 0 rgba(1,1,1,0.468);
}

.jetui-table-addaction .ToolButton {
  display: block;
  border-radius: 2px;
  background-color: transparent;
  transition: background-color .2s ease-in-out;
}

.jetui-table-addaction.jetui-table-addaction-open .ToolButton {
  display: block;
  background-color: var(--jetui-color-surface);
}

.jetui-table-addaction-panel {
  overflow: hidden;
  text-overflow: clip;
  transition: height .2s ease-in-out;
  padding: 6px;
}

.jetui-table-addaction-panel > span,
.jetui-table-addaction-panel input {
  display: block;
  width: 400px;
  margin-bottom: 32px;
}