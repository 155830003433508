.jetui-layout-item {
  margin: 0;
  box-sizing: border-box;
  flex: 1 0;
}

.jetui-layout-item-sm-1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%
}

.jetui-layout-item-sm-2 {
  flex-grow: 0;
  max-width: 16.666666%;
  flex-basis: 16.666666%
}

.jetui-layout-item-sm-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%
}

.jetui-layout-item-sm-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%
}

.jetui-layout-item-sm-5 {
  flex-grow: 0;
  max-width: 41.666666%;
  flex-basis: 41.666666%
}

.jetui-layout-item-sm-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%
}

.jetui-layout-item-sm-7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%
}

.jetui-layout-item-sm-8 {
  flex-grow: 0;
  max-width: 66.666666%;
  flex-basis: 66.666666%
}

.jetui-layout-item-sm-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%
}

.jetui-layout-item-sm-10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%
}

.jetui-layout-item-sm-11 {
  flex-grow: 0;
  max-width: 91.666666%;
  flex-basis: 91.666666%
}

.jetui-layout-item-sm-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%
}

@media (min-width: 768px) {
  .jetui-layout-item-md-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%
  }

  .jetui-layout-item-md-2 {
    flex-grow: 0;
    max-width: 16.666666%;
    flex-basis: 16.666666%
  }

  .jetui-layout-item-md-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%
  }

  .jetui-layout-item-md-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%
  }

  .jetui-layout-item-md-5 {
    flex-grow: 0;
    max-width: 41.666666%;
    flex-basis: 41.666666%
  }

  .jetui-layout-item-md-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%
  }

  .jetui-layout-item-md-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%
  }

  .jetui-layout-item-md-8 {
    flex-grow: 0;
    max-width: 66.666666%;
    flex-basis: 66.666666%
  }

  .jetui-layout-item-md-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%
  }

  .jetui-layout-item-md-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%
  }

  .jetui-layout-item-md-11 {
    flex-grow: 0;
    max-width: 91.666666%;
    flex-basis: 91.666666%
  }

  .jetui-layout-item-md-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%
  }
}

@media (min-width: 992px) {
  .jetui-layout-item-lg-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%
  }

  .jetui-layout-item-lg-2 {
    flex-grow: 0;
    max-width: 16.666666%;
    flex-basis: 16.666666%
  }

  .jetui-layout-item-lg-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%
  }

  .jetui-layout-item-lg-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%
  }

  .jetui-layout-item-lg-5 {
    flex-grow: 0;
    max-width: 41.666666%;
    flex-basis: 41.666666%
  }

  .jetui-layout-item-lg-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%
  }

  .jetui-layout-item-lg-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%
  }

  .jetui-layout-item-lg-8 {
    flex-grow: 0;
    max-width: 66.666666%;
    flex-basis: 66.666666%
  }

  .jetui-layout-item-lg-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%
  }

  .jetui-layout-item-lg-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%
  }

  .jetui-layout-item-lg-11 {
    flex-grow: 0;
    max-width: 91.666666%;
    flex-basis: 91.666666%
  }

  .jetui-layout-item-lg-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%
  }
}

@media (min-width: 1200px) {
  .jetui-layout-item-xl-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%
  }

  .jetui-layout-item-xl-2 {
    flex-grow: 0;
    max-width: 16.666666%;
    flex-basis: 16.666666%
  }

  .jetui-layout-item-xl-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%
  }

  .jetui-layout-item-xl-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%
  }

  .jetui-layout-item-xl-5 {
    flex-grow: 0;
    max-width: 41.666666%;
    flex-basis: 41.666666%
  }

  .jetui-layout-item-xl-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%
  }

  .jetui-layout-item-xl-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%
  }

  .jetui-layout-item-xl-8 {
    flex-grow: 0;
    max-width: 66.666666%;
    flex-basis: 66.666666%
  }

  .jetui-layout-item-xl-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%
  }

  .jetui-layout-item-xl-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%
  }

  .jetui-layout-item-xl-11 {
    flex-grow: 0;
    max-width: 91.666666%;
    flex-basis: 91.666666%
  }

  .jetui-layout-item-xl-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%
  }
}