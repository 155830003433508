.jetui-histogram rect {
  cursor: pointer;
}

.jetui-histogram > rect.jetui-histogram-rect-Succeeded {
  fill: var(--jetui-color-good);
}

.jetui-histogram > rect.jetui-histogram-rect-Running {
  fill: var(--jetui-color-warning);
}

.jetui-histogram > rect.jetui-histogram-rect-Failed {
  fill: var(--jetui-color-error);
}

.jetui-histogram > rect.jetui-histogram-rect-Canceled {
  fill: var(--jetui-color-warning);
}