.jetui-shade {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
}

.jetui-shade-center {
  justify-content: center;
  align-items: center;
}

.jetui-theme-dark .jetui-shade-darkened {
  -webkit-animation: shade-darken-dark  0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: shade-darken-dark  0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.jetui-theme-dark .jetui-shade-lightened {
  -webkit-animation: shade-lighten-dark  0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: shade-lighten-dark  0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@-webkit-keyframes shade-darken-dark {
  0% {
    background-color: rgba(0,0,0,0);
  }
  100% {
    background-color: rgba(0,0,0,0.75);
  }
}
@keyframes shade-darken-dark  {
  0% {
    background-color: rgba(0,0,0,0);
  }
  100% {
    background-color: rgba(0,0,0,0.75);
  }
}

@-webkit-keyframes shade-lighten-dark  {
  0% {
    background-color: rgba(0,0,0,0.75);
  }
  100% {
    background-color: rgba(0,0,0,0);
  }
}
@keyframes shade-lighten-dark  {
  0% {
    background-color: rgba(0,0,0,0.75);
  }
  100% {
    background-color: rgba(0,0,0,0);
  }
}

.jetui-shade-darkened {
  -webkit-animation: shade-darken  0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: shade-darken 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.jetui-shade-lightened {
  -webkit-animation: shade-lighten  0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: shade-lighten  0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@-webkit-keyframes shade-darken {
  0% {
    background-color: rgba(0,0,0,0);
  }
  100% {
    background-color: rgba(0,0,0,0.25);
  }
}
@keyframes shade-darken  {
  0% {
    background-color: rgba(0,0,0,0);
  }
  100% {
    background-color: rgba(0,0,0,0.25);
  }
}

@-webkit-keyframes shade-lighten {
  0% {
    background-color: rgba(0,0,0,0.25);
  }
  100% {
    background-color: rgba(0,0,0,0);
  }
}
@keyframes shade-lighten {
  0% {
    background-color: rgba(0,0,0,0.25);
  }
  100% {
    background-color: rgba(0,0,0,0);
  }
}