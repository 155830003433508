.Profile label {
  display: block;
}

.Profile__Section {
  padding-bottom: 20px;
  margin-bottom: 20px;
  max-width: 600px;
}

.Profile__Section:last-child {
  border-bottom: none;
}

.Profile__Section h2 {
  font-weight: 300;
  margin: 0px 0px 20px 0px;
}

.Profile__Section > i {
  margin: 0px 4px;
}