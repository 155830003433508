.jetui-breadcrumb > a {
  text-decoration: none;
  color: #888;
  display: inline-block;
  margin-bottom: 8px;
}

.jetui-breadcrumb::after {
  font-family: 'Material Icons';
  content: '\e5cc';
  user-select: none;
  margin: 0 8px;
  font-size: 18px;
  vertical-align: middle;
}

.jetui-breadcrumb:last-child::after {
  content: '';
}