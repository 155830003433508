.jetui-form-actions > div > button {
  margin-right: 8px;
}

.jetui-form .jetui-form-actions > .jetui-status-container {
  margin: 8px 0 0 0;
}

.jetui-form .jetui-form-actions > .jetui-status-container > .jetui-status {
  margin: 0 8px 0 0;
}