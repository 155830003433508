.jetui-theme-light {
  --jetui-color-text: #222;
  --jetui-color-text-darkened: #444;
  --jetui-color-background: #fafafa;
  --jetui-color-surface: #e7e7e7;
  --jetui-color-surface-light: #f5f5f5;
  --jetui-color-primary: #1b75bc;
  --jetui-color-primary-light: #3d91c9;
  --jetui-color-primary-verylight: #ddf1ff;
  --jetui-color-primary-dark: #165fa3;
  --jetui-color-warning: #efb032;
  --jetui-color-error: #ef3232;
  --jetui-color-good: #25be20;
}

.jetui-theme-dark {
  --jetui-color-text: #e8e8e8;
  --jetui-color-text-darkened: #ccc;
  --jetui-color-background: #161619;
  --jetui-color-card: #252427;
  --jetui-color-surface: #36343a;
  --jetui-color-surface-light: #4e4e4e;
  --jetui-color-primary: #3d91c9;
  --jetui-color-primary-verylight: #ddf1ff;
  --jetui-color-primary-light: #5facd7;
  --jetui-color-primary-dark: #1b75bc;
  --jetui-color-warning: #b17b10;
  --jetui-color-error: #cc4848;
  --jetui-color-good: #2ba327;
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--jetui-color-background);
  color: var(--jetui-color-text);
  font-size: 14px;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 16px 0;
}

h1 {
  font-size: 32px;
  font-weight: 300;
}

h2 {
  font-weight: 300;
  font-size: 24px;
}

h3 {
  font-weight: 400;
  font-size: 20px;
}

h4 {
  font-weight: 500;
  font-size: 16px;
}

@media(max-width: 767px) {
  body {
    font-size: 12px;
  }

  h1 {
    font-size: 24px;
    font-weight: 300;
  }
  
  h2 {
    font-weight: 300;
    font-size: 20px;
  }
  
  h3 {
    font-weight: 400;
    font-size: 16px;
  }
  
  h4 {
    font-weight: 500;
    font-size: 12px;
  }
}

i {
  vertical-align: middle;
}

b {
  font-weight: 500;
}

a {
  text-decoration: none;
  color: var(--jetui-color-text-darkened);
}

a:hover {
  color: var(--jetui-color-primary);
}

.jetui-theme-dark a:hover {
  color: var(--jetui-color-primary-light);
}

.material-icons {
  vertical-align: middle;
}

hr {
  border: unset;
  border-bottom: 1px solid var(--jetui-color-surface);
}

.jetui-theme-dark hr {
  border-bottom: 1px solid var(--jetui-color-surface-light);
}

button {
  font-family: unset;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  border-radius: 2px;
  color: white;
  background-color: var(--jetui-color-primary);
  vertical-align: middle;
}

button:hover {
  background-color: var(--jetui-color-primary-light);
}

button:disabled {
  background-color: var(--jetui-color-surface);
  cursor: default;
}