.User {
  float: right;
  cursor: pointer;
}

div.User__Avatar {
  margin: 12px;
}

@media(max-width: 767px) {
  div.User__Avatar {
    width: 32px;
    height: 32px;
    margin: 8px;
    font-size: 20px;
  }
}

.User > span {
  margin-right: 8px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  transition: border .1s ease-out;
  padding-bottom: 4px;
}

.User > span > i {
  vertical-align: middle;
}

.User > span:hover {
  border-bottom: 2px solid var(--jetui-color-primary);
}

.User__Dropdown {
  width: 300px;
}