.jetui-sliderule-outer {
  height: 8px;
  background-color: var(--jetui-color-surface);
  border-radius: 10px;
  margin-bottom: 12px;
  position: relative;
}

.jetui-sliderule-inner {
  height: 16px;
  width: 16px;
  background-color: var(--jetui-color-primary);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  top: -4px;
  touch-action: none;
}