.jetui-layout {
  display: flex;
  flex-wrap: wrap;
}

.jetui-layout-spacing-0 > div.jetui-layout-item {
  padding: 0;
}

.jetui-layout-spacing-1 > div.jetui-layout-item {
  padding: 4px;
}

.jetui-layout-spacing-2 > div.jetui-layout-item {
  padding: 8px;
}

.jetui-layout-spacing-3 > div.jetui-layout-item {
  padding: 16px;
}

.jetui-layout-spacing-4 > div.jetui-layout-item {
  padding: 24px;
}

.jetui-layout-spacing-1 > div.jetui-layout-item:first-child,
.jetui-layout-spacing-2 > div.jetui-layout-item:first-child,
.jetui-layout-spacing-3 > div.jetui-layout-item:first-child,
.jetui-layout-spacing-4 > div.jetui-layout-item:first-child {
  padding-left: 0;
}

.jetui-layout-spacing-1 > div.jetui-layout-item:last-child,
.jetui-layout-spacing-2 > div.jetui-layout-item:last-child,
.jetui-layout-spacing-3 > div.jetui-layout-item:last-child,
.jetui-layout-spacing-4 > div.jetui-layout-item:last-child {
  padding-right: 0;
}