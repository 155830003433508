.jetui-content {
  margin: 16px 18px;
}

@media (min-width: 768px) {
  .jetui-content {
    margin: 16px 32px;
  }
}

@media (min-width: 992px) {
  .jetui-content {
    margin: 16px 6%;
  }
}

@media (min-width: 1200px) {
  .jetui-content {
    margin: 16px 6%;
  }
}