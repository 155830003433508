.jetui-dialog {
  flex-basis: 400px;
  background-color: var(--jetui-color-surface-light);
  opacity: 0.0;
  transition: opacity 0.5s ease-in-out;
  max-width: 95vw;
}

.jetui-dialog-open {
  opacity: 1.0;
}

.jetui-dialog-actions {
  margin-top: 8px;
  float: right;
}

.jetui-dialog-actions button {
  margin-left: 8px;
}