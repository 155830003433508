.jetui-dropdown-item {
  padding: 12px;
  cursor: pointer;
}

.jetui-dropdown-item:hover {
  background-color: var(--jetui-color-surface-light);
}

.jetui-dropdown-item > i {
  width: 32px;
  padding: 0 auto;
  color: var(--jetui-color-primary-dark);
}

.jetui-theme-dark .jetui-dropdown-item > i {
  color: var(--jetui-color-primary-light);
}