.jetui-toolbutton {
  display: inline-block;
  margin: 0 16px 8px 0;
  padding: 4px;
  cursor: pointer;
  background: unset;
  color: unset;
  text-transform: unset;
  font-weight: unset;
  font-size: unset;
  outline: none;
}

.jetui-toolbutton.jetui-toolbutton-collapse {
  margin: 0;
}

.jetui-toolbutton:hover {
  background-color: var(--jetui-color-surface-light);
  outline: none;
}

button.jetui-toolbutton:focus {
  outline: none;
}

button.jetui-toolbutton:disabled {
  background-color: unset;
  cursor: default;
}

button.jetui-toolbutton:disabled > i {
  color: #888;
}

.jetui-toolbutton i {
  font-size: 24px;
  width: 24px;
  vertical-align: middle;
  color: var(--jetui-color-primary);
}

.jetui-toolbutton > span {
  padding: 0px 8px;
}

.jetui-toolbutton.jetui-toolbutton-good i,
.jetui-toolbutton.jetui-toolbutton-good:disabled i {
  color: var(--jetui-color-good);
}

.jetui-toolbutton.jetui-toolbutton-warning i,
.jetui-toolbutton.jetui-toolbutton-warning:disabled i {
  color: var(--jetui-color-warning);
}

.jetui-toolbutton.jetui-toolbutton-error i,
.jetui-toolbutton.jetui-toolbutton-error:disabled i {
  color: var(--jetui-color-error);
}