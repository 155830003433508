.Users {
  max-width: 1200px;
}

.Users .jetui-card {
  margin: 16px 0 16px 0;
}

.Users h3 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Users__Delete {
  text-align: right;
}

.Users__EditDialog .jetui-input-container {
  display: block;
}

.Users__EditDialog .jetui-input {
  width: 100%;
}