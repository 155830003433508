.jetui-topnav {
  display: inline-block;
  font-size: 14px;
}

.jetui-topnav-link {
  margin: 0 20px;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 2px solid rgba(0,0,0,0);
  transition: border-bottom .1s ease-out;
}

a.jetui-topnav-link {
  color: unset;
}

.jetui-topnav-link:first-child {
  margin-left: 40px;
}

.jetui-topnav-link:last-child {
  margin-right: 40px;
}

.jetui-topnav-link:hover {
  border-bottom: 2px solid var(--jetui-color-primary);
}