.Notifications {
  max-width: 800px;
}

.Notifications__Webhook__Dialog {
  height: 90vh;
  min-width: 80vw;
}

.Notifications__Webhook__Dialog .jetui-form-fields {
  overflow-y: scroll;
  height: 70vh;
  padding-right: 16px;
  margin-bottom: 16px;
}