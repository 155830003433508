.FlowDetail__View {
  display: flex;  
  border: 1px solid #eee;
}

.FlowDetail__Graph {
  padding: 0px 10px;
  background-color: #222;
  height: 140px;
  border-bottom: 1px solid var(--jetui-color-surface-light);
  border-top: 1px solid var(--jetui-color-surface-light);
  margin-bottom: 12px;
}

.jetui-theme-light .FlowDetail__Graph {
  background-color: var(--jetui-color-surface-light);
  border-bottom: 1px solid var(--jetui-color-surface);
  border-top: 1px solid var(--jetui-color-surface);
}

.jetui-theme-light .FlowDetail__Graph svg path {
  stroke: #bbb;
}

.FlowDetail__Graph svg {
  height: 100%;
  max-width: 100%;
}

.FlowDetail__JobLog__Container {
  display: flex;
  flex-direction: column;
}
