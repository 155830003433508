.Admin__Menu {
  font-weight: 400;
  border-bottom: 1px solid var(--jetui-color-surface);
}

.Admin__Menu .ToolButton {
  padding: 4px;
  margin-bottom: 8px;
  margin-right: 16px;
}

.Admin__Menu > div:hover {
  background-color: var(--jetui-color-surface-light);
}

.Admin__Section {
  padding: 10px;
}

.Admin__Section h1 {
  margin: 0 0 14px 0;
  font-weight: 100;
}