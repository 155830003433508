.jetui-capsule {
  padding: 4px 10px;
  border-radius: 999px;
  text-align: center;
  display: inline-block;
  color: var(--jetui-color-background);
  font-size: 12px;
}

.jetui-theme-dark .jetui-capsule {
  color: var(--jetui-color-text);
}

.jetui-capsule.jetui-capsule-primary {
  background-color: var(--jetui-color-primary);
}

.jetui-capsule.jetui-capsule-warning {
  background-color: var(--jetui-color-warning);
}

.jetui-capsule.jetui-capsule-error {
  background-color: var(--jetui-color-error);
}

.jetui-capsule.jetui-capsule-good {
  background-color: var(--jetui-color-good);
}

.jetui-capsule.jetui-capsule-neutral {
  background-color: #888;
}