.jetui-spinner {
  animation: rotator 5.6s linear infinite;
  vertical-align: middle;
  padding: 10%;
  box-sizing: border-box;
  stroke: var(--jetui-color-primary);
}

button circle.jetui-spinner-outer,
button circle.jetui-spinner-inner {
  stroke: white;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.jetui-spinner-outer {
  stroke-dasharray: 187;
  stroke-dashoffset: 12;
  transform-origin: center;
  animation:
    dash-outer 1.4s ease-in-out infinite
}

@keyframes dash-outer {
 0% { 
   stroke-dashoffset: 12;
   transform:rotate(-78deg);
  }
 50% {
   stroke-dashoffset: 177;
   transform:rotate(239deg);
 }
 100% {
   stroke-dashoffset: 12;
   transform:rotate(282deg);
 }
}

.jetui-spinner-inner {
  stroke-dasharray: 106;
  stroke-dashoffset: 12;
  transform-origin: center;
  animation:
    dash-inner 1.4s ease-in-out infinite
}

.jetui-theme-dark .jetui-spinner-outer,
.jetui-theme-dark .jetui-spinner-inner {
  stroke: var(--jetui-color-primary-light);
}

@keyframes dash-inner {
  0% { stroke-dashoffset: 12 }
  50% {
    stroke-dashoffset: 110;
    transform:rotate(320deg);
  }
  100% {
    stroke-dashoffset: 12;
    transform:rotate(720deg);
  }
 }