.jetui-logo {
  box-sizing: border-box;
  display: inline-block;
  user-select: none;
}

.jetui-logo-linked {
  cursor: pointer;
}

.jetui-logo > svg {
  vertical-align: middle;
}

.jetui-logo-sm {
  font-weight: 700;
  font-size: 14px;
  margin: 4px;
}

.jetui-logo-md {
  font-weight: 900;
  font-size: 20px;
  margin: 4px;
}

.jetui-logo-lg {
  font-weight: 900;
  font-size: 32px;
  margin: 8px;
}

.jetui-logo-sm > svg {
  height: 40px;
}

.jetui-logo-md > svg {
  height: 64px;
}

.jetui-logo-lg > svg {
  height: 96px;
}

.jetui-logo-sm > span {
  margin-left: 4px;
}

.jetui-logo-md > span {
  margin-left: 8px;
}

.jetui-logo-lg > span {
  margin-left: 12px;
}

.st0 {
  fill:none;
  stroke:var(--jetui-color-text-darkened);
  stroke-width:32;
  stroke-miterlimit:10;
}

.st1 {
  fill:var(--jetui-color-primary);
}

.st2 {
  fill:none;
  stroke:var(--jetui-color-text-darkened);
  stroke-width:30;
  stroke-miterlimit:10;
}

.st3 {
  fill:none;
  stroke:var(--jetui-color-text-darkened);
  stroke-width:20;
  stroke-miterlimit:10;
}