.GanttChart {
  background-color: #333;
}

.GanttChart line {
  color: #ddd;
}

.GanttChart path {
  color: #ddd;
}

.GanttChart text {
  color: #ddd;
  font-family: unset;
  text-rendering: optimizeLegibility;
}