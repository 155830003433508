.jetui-selection > select {
  font-family: unset;
  font-size: unset;
  background-color: transparent;
  border: 0px;
  border-bottom: 2px solid var(--jetui-color-surface);
  outline: 0px;
  transition: border-bottom .2s ease-out;
  margin-bottom: 28px;
  min-width: 200px;
  color: var(--jetui-color-text);
}

.jetui-selection {
  position: relative;
  display: inline-block;
}

.jetui-selection > select:focus {
  border-bottom: 2px solid var(--jetui-color-primary);
}

.jetui-selection > label {
  position: absolute;
  top: 0;
  left: 0;
  color: #888;
  transition: all .3s cubic-bezier(0,.7,.3,1);
  cursor: pointer;
  pointer-events: none;
}

.jetui-selection > label.jetui-selection-label-selected {
  position: absolute;
  top: 24px;
  left: 4px;
  font-size: 12px;
}

.jetui-selection-clear {
  position: absolute;
  cursor: pointer;
  font-size: 14px;
  right: 16px;
  top: 4px;
}

.jetui-selection option {
  background-color: var(--jetui-color-surface);
  border: 0px;
  outline: 0px;
}

.jetui-selection option:focus {
  border: 0px;
  outline: 0px;
}