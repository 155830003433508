.jetui-timepicker input {
  display: inline-block;
  margin-right: 8px;
  width: 3em;
  vertical-align: middle;
}

.jetui-timepicker > div > div.jetui-sliderule-outer {
  vertical-align: middle;
  display: inline-block;
  margin-top: 8px;
  width: calc(100% - 3em - 8px);
}