:root {
  --jetstream-blue: #1b75bc;
  --jetstream-blue-highlight: #2984cc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table tbody tr td div.MuiTextField-root {
  width: 100%;
}

input[type=submit] {
  border: none;
  border-radius: 20px;
  background-color: var(--jetstream-blue);
  font-family: unset;
  font-size: 16px;
  padding: 5px 10px;
  color: white;
  box-shadow: 0px 0px 16px 0px rgba(50, 50, 50, 0.4);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

input[type=submit]:hover {
  background-color: #6896bb;
}

.Jetstream__Card {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border: 1px solid #ddd;
}
