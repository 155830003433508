.Schedule {
  border-top: 1px solid var(--jetui-color-surface);
  padding-top: 8px;
}

.Schedule button {
  margin-right: 8px;
}

.Schedule__Card {
  max-width: 500px;
  margin-bottom: 16px;
}

.Schedule__Form {
  max-width: 500px;
}