.jetui-datepicker {
  text-align: center;
  width: 240px;
}

.jetui-datepicker th {
  font-weight: 500;
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
}

.jetui-datepicker td {
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
}

.jetui-datepicker table {
  margin: 0;
  padding: 0;
  table-layout: fixed;
}

.jetui-datepicker i {
  display: inline-block;
  cursor: pointer;
}

.jetui-datepicker h4 {
  display: inline-block;
  width: 192px;
}

.jetui-datepicker-active {
  background-color: var(--jetui-color-surface);
}
