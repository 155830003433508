.Invite form input[type=submit] {
  margin-left: 20px;
}

.Invite {
  max-width: 800px;
  margin-bottom: 20px;
}

.Invite table tbody tr td div.MuiTextField-root {
  width: 100%;
}