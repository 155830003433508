.jetui-input {
  border: none;
  border-bottom: 2px solid var(--jetui-color-surface);
  height: 20px;
  font-family: unset;
  font-size: unset;
  transition: border-bottom 0.2s ease-out;
  margin: 0px;
  background-color: transparent;
  color: unset;
  width: 100%;
  display: block;
  padding: 0;
}

.jetui-input[type=radio] {
  border: none;
  height: unset;
  transition: unset;
  width: unset;
  display: unset;
}

.jetui-theme-dark .jetui-input {
  border-bottom: 2px solid var(--jetui-color-surface-light);
}

.jetui-input:focus {
  outline: none;
  border-bottom: 2px solid var(--jetui-color-primary);
}

.jetui-input-container {
  position: relative;
  padding: 4px 0 16px 0;
  width: 100%;
  margin: 0 0 24px 0;
}

.jetui-input-container > label {
  position: absolute;
  color: #888;
  transition: all .3s cubic-bezier(0,.7,.3,1);
  pointer-events: none;
  bottom: 0px;
  font-size: 12px;
}

.jetui-input:placeholder-shown ~ label {
  bottom: 18px;
  left: 0px;
  font-size: unset;
}

.jetui-input:focus ~ label {
  bottom: 0px;
  font-size: 12px;
}

.jetui-input-container > label.jetui-input-label-active {
  position: absolute;
  left: 0;
  top: 30px;
  font-size: 12px;
}

.jetui-input-error:focus {
  border-bottom: 2px solid var(--jetui-color-error);
}

.jetui-input-container i {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
  color: var(--jetui-color-error);
  cursor: default;
}