:root {
  --jetstream-blue: #1b75bc;
}

.App-menu {
  background-color: #eee;
  height: 60px; 
  padding: 10px 20px 10px 20px;
}

.App-content {
  padding: 10px 104px 10px 104px;
}

.App-menu ul {
  list-style: none;
  display: inline;
}

.App-menu ul li {
  display: inline;
  padding-right: 20px;
}

.App-logo {
  font-weight: 900; 
  line-height: 60px;
  font-size: 24px;
  display: inline;
}

.App-logo img {
  vertical-align: middle;
  height: 60px;
  padding-right: 10px;
}

.Header-user {
  float: right;
  line-height: 60px;
}

.Header-user img {
  vertical-align: middle;
  border-radius: 50%;
  margin-left: 10px;
}
