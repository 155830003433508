div.jetui-card.Monitor__RecentTable__Card {
  margin: 24px 0;
}

.Monitor__RecentTable__Card__Status {
  text-align: right;
}

.Monitor__Section {
  margin-bottom: 32px;
}

.Monitor__Section:last-child {
  margin-bottom: 0;
}

.Monitor__Loading {
  text-align: center;
}